<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {email, required} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import emailPlaceholders from "@/helpers/email-placeholders";
import {errorCatcher} from "@/helpers/error-catcher";
import {paginationHelper} from "@/helpers/pagination-helper";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";
import {toastHelper} from "../../../helpers/toast-helper";
import {swalHelper} from "../../../helpers/swal-helper";
import Swal from "sweetalert2";

export default {
  components: {
    CustomCardSubtitle,
    Layout,
    PageHeader,
    Multiselect,
  },

  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",
        items: []
      },


      template: {
        type: "",
        name: "",
        title: "",
        content: ""
      },

      testEmail: "",
      testShopOrderIdAsNumber: null,

      createOrEditShowModal: false,
      templateTypes: [],
      activeTemplates: new Map(),
      filterType: null,
      emailPlaceholders: emailPlaceholders
    };
  },

  validations: {
    template: {
      type: { required },
      emailSenderVisibleName: { required },
      emailSenderAddress: { required, email },
      name: { required },
      title: { required },
      content: { required }
    }
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('menuitems.admin.list.email-templates'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        { key: "name", label: this.$t('message.name') },
        { key: "type", slot: true, label: this.$t('email-templates.type') },
        { key: "title", label: this.$t('email-templates.title') },
        { key: "status", slot: true, label: "Status" },
        { key: "action", slot: true, label: this.$t('table.actions') }
      ]
    },

    getPlaceholder(emailPlaceholder) {
      if (emailPlaceholder.i18nTranslation === "email-templates.placeholders.first-name-in-vocative") {
        return `[[${emailPlaceholder.placeholder}]]!`
      }

      return `[[${emailPlaceholder.placeholder}]]`
    },

    async loadTemplates() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/email-template/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: this.table.perPage,
            type: this.filterType
          }
        });

        this.table.items = data.emailTemplates
        this.table.totalRows = data.count;
        this.table.rows = data.count
      } catch (error) {
        errorCatcher.catchErrors(error)
      }

      return this.table.items
    },

    async loadAllTypes() {
      try {
        const {data} = await axios.get(`/email-template/types`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.templateTypes = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadActiveList() {
      try {
        const {data} = await axios.get(`/email-template/list-active`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.activeTemplates = new Map(data.map((obj) => [obj.templateId, {
          templateId: obj.templateId,
          type: obj.type
        }]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    hideCreateOrEditModal() {
      this.submitted = false;
      this.createOrEditShowModal = false;
      this.template =  {
        type: "",
        name: "",
        title: "",
        content: ""
      }
    },

    editTemplate(template) {
      this.template = Object.assign({}, template);
      this.createOrEditShowModal = true
    },

    createOrEditTemplate() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const json = JSON.stringify(this.template);

      axios.put(`/email-template`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        toastHelper.success(this.$t(this.template.id ? "email-templates.edit-template.success" : "email-templates.create-template.success"))
        this.$refs.table.refresh()
        this.hideCreateOrEditModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    deleteTemplate(template) {
      swalHelper.yesOrNo(() => {
        const json = {
          id: template.id,
        }

        axios.delete(`/email-template`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: json
        }).then(() => {
          toastHelper.successTitle(this.$t('message.deleted'), this.$t('email-templates.delete.success'))
          this.$refs.table.refresh()
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        });
      })
    },

    setActivate(template) {
      swalHelper.yesOrNoWithSubTitle(() => {
        const json = {
          type: template.type,
          templateId: template.id
        };

        axios.put(`/email-template/activate`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }).then(() => {
          toastHelper.success(this.$t('email-templates.activate.success'))

          this.$refs.table.refresh();
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        });
      }, false)
    },

    isActive(template) {
      return this.activeTemplates.get(template.id);
    },

    getTypeName(type) {
      return this.$t(`email-templates.types.` + type);
    },

    pasteText(text) {
      const inputElement = this.$refs.contentInput;
      const startPosition = inputElement.selectionStart;
      const endPosition = inputElement.selectionEnd;

      const beforeCursor = this.template.content.substring(0, startPosition);
      const afterCursor = this.template.content.substring(endPosition);

      this.template.content = beforeCursor + text + afterCursor;

      const newCursorPosition = startPosition + text.length
      inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
    },

    setToFirstPageAndRefresh() {
      this.table.currentPage = 1
      this.$refs.table.refresh()
    },

    changeFilterType(filterType) {
      this.filterType = filterType
      this.setToFirstPageAndRefresh()
    },

    async sendTestEmail() {
      if (!this.template.type) {
        await Swal.fire("Błąd!", "Ustaw typ", "error");
        return
      }

      if (!this.template.title) {
        await Swal.fire("Błąd!", "Wprowadź Tytuł", "error");
        return
      }

      if (!this.testEmail) {
        await Swal.fire("Błąd!", "Wprowadź e-mail", "error");
        return
      }

      if (!this.testShopOrderIdAsNumber) {
        await Swal.fire("Błąd!", "Wprowadź nr zamówienia", "error");
        return
      }

      try {
        const json = {
          email: this.testEmail,
          shopOrderIdAsNumber: this.testShopOrderIdAsNumber,
          emailTemplate: this.template
        }

        await axios.post(`/email-template/send-test-email`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        await Swal.fire("Sukces!", "E-mail został wysłany!", "success");
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }

  },

  async created() {
    try {
      await this.loadAllTypes()
      await this.loadActiveList()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('menuitems.admin.list.email-templates')" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <custom-card-subtitle title="Akcje"/>

            <div class="button-items">
              <a class="btn btn-success mb-2" @click="createOrEditShowModal = true">
                <i class="mdi mdi-plus mr-2"></i> {{ $t('email-templates.create-template.button') }}
              </a>
            </div>

            <div class="row py-4">
              <custom-card-subtitle title="Filtrowanie"/>

              <div class="col-12 col-lg-3">
                <multiselect
                    :value="filterType"
                    @select="changeFilterType"
                    @remove="changeFilterType(null)"
                    :options="templateTypes"
                    :searchable="true"
                    :placeholder="$t('message.select-filtration-type')"
                    :custom-label="getTypeName"
                    :show-labels="false"
                />
              </div>
            </div>

            <ecat-table
                ref="table"
                :fields="getFields()"
                :items="loadTemplates"
                :pagination-top="true"
                :table="table">

              <template v-slot:type="data">
                <span class="badge badge-soft-info font-size-11"> {{ $t(`email-templates.types.` + data.item.type) }}</span>
              </template>

              <template v-slot:status="data">
                <span :class="isActive(data.item) ? 'badge badge-soft-success' : 'badge badge-soft-danger'" class="font-size-11"> {{ $t(isActive(data.item) ? 'table.active' : 'table.inactive') }}</span>
              </template>

              <template v-slot:action="data">
                <div style="width: 120px;">
                  <a @click="editTemplate(data.item)" class="clickable-element mr-3 text-primary"><i class="mdi mdi-pencil font-size-18"></i></a>
                  <a @click="setActivate(data.item)" class="clickable-element mr-3 text-primary"><i class="font-size-18" :class="isActive(data.item) ? 'fa fa-toggle-on' : 'fa fa-toggle-off'"></i></a>
                  <a @click="deleteTemplate(data.item)" class="clickable-element text-danger"><i class="mdi mdi-trash-can font-size-18"></i></a>
                </div>
              </template>
            </ecat-table>
        </div>
      </div>
    </div>
    </div>

    <!-- Modal -->
    <b-modal
        id="modal-1"
        :scrollable="true"
        size="xl"
        v-model="createOrEditShowModal"
        :title="$t(template.id ? 'email-templates.edit-template.title' : 'email-templates.create-template.title')"
        title-class="font-18"
        hide-footer
        @hide="hideCreateOrEditModal"
        @esc="hideCreateOrEditModal">
      <form>

        <div class="form-group">
          <label for="name">{{$t('message.name')}}</label>
          <input id="name" v-model="template.name" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.template.name.$error }"/>
          <div v-if="submitted && !$v.template.name.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label>Widoczna nazwa E-mail</label>
          <input v-model="template.emailSenderVisibleName" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.template.emailSenderVisibleName.$error }"/>
          <div v-if="submitted && !$v.template.emailSenderVisibleName.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label>E-mail</label>
          <input v-model="template.emailSenderAddress" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.template.emailSenderAddress.$error }"/>
          <div v-if="submitted && !$v.template.emailSenderAddress.required" class="invalid-feedback">{{ $t('message.required')}}</div>
          <div v-if="submitted && !$v.template.emailSenderAddress.email" class="invalid-feedback">{{ $t('message.email-error')}}</div>
        </div>

        <div class="form-group">
          <label for="type">{{$t('email-templates.type')}}</label>
          <select v-model="template.type" class="custom-select" id="type" :class="{ 'is-invalid': submitted && $v.template.type.$error }">
            <option v-for="(type, index) in templateTypes" :key="index" :value="type" >{{ $t(`email-templates.types.` + type) }}</option>
          </select>

          <div v-if="submitted && !$v.template.type.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label for="title">{{$t('email-templates.title')}}</label>
          <input id="title" v-model="template.title" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.template.title.$error }"/>
          <div v-if="submitted && !$v.template.title.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label for="content">{{$t('email-templates.content')}}</label>
<!--          <ckeditor id="content" v-model="template.content" :editor="editor"></ckeditor>-->
<!--          <div v-if="submitted && !$v.template.content.required" class="invalid-feedback">{{ $t('message.required')}}</div>-->

          <div>
            <a v-for="(emailPlaceholder, index) in emailPlaceholders" :key="index" @click="pasteText(getPlaceholder(emailPlaceholder))" class="btn btn-info mb-2 mx-2">
              {{ $t(emailPlaceholder.i18nTranslation) }}
            </a>
          </div>

          <textarea ref="contentInput" id="content" v-model="template.content" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.template.content.$error }"/>
          <div v-if="submitted && !$v.template.content.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group mb-4">
          <div class="row mb-2">
            <div class="col-12 col-lg-3">
              <label>Wyślij testowy E-mail:</label>
              <input v-model="testEmail" type="email" class="form-control" />
            </div>

            <div class="col-12 col-lg-3">
              <label>Nr zamówienia:</label>
              <input v-model.number="testShopOrderIdAsNumber" type="number" class="form-control" />
            </div>
          </div>

          <ecat-button :callback="() => sendTestEmail()" variant="primary" message="Wyślij" />
        </div>

        <h4 class="text-center">{{ $t('email-templates.preview') }}</h4>

        <div v-html="template.content" />

        <div class="text-right">
          <b-button @click="createOrEditTemplate" variant="success">{{ $t(template.id ? 'email-templates.edit-template.button' : 'email-templates.create-template.button') }}</b-button>
          <b-button class="ml-1" variant="danger" @click="hideCreateOrEditModal">{{  $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

  </Layout>
</template>