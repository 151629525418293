const emailPlaceholders = [
    [
        '${user.firstName}',
        'email-templates.placeholders.first-name-in-vocative'
    ],
    [
        '${user.firstName}',
        'email-templates.placeholders.first-name'
    ],
    [
        '${user.lastName}',
        'email-templates.placeholders.last-name'
    ],
    [
        '${verificationCode}',
        'email-templates.placeholders.verification-code'
    ],
    [
        '${signedUrl}',
        'email-templates.placeholders.verification-url'
    ],
    [
        '${expiresAt}',
        'email-templates.placeholders.verification-expired-at'
    ],
    [
        '${user.email}',
        'email-templates.placeholders.email'
    ],
    [
        '${user.phoneNumber}',
        'email-templates.placeholders.phone-number'
    ],
    [
        '${cancelledProducts}',
        'email-templates.placeholders.cancelled-products'
    ],
    [
        '${summaryPrice}',
        'email-templates.placeholders.summary-price'
    ],
    [
        '${totalCost}',
        'email-templates.placeholders.total-cost'
    ],
    [
        '${trackingUrl}',
        'email-templates.placeholders.tracking-url'
    ],
    [
        '${shop.phoneNumber}',
        'email-templates.placeholders.shop-phone-number'
    ],
    [
        '${shop.emailAddress}',
        'email-templates.placeholders.shop-email'
    ],
    [
        '${invoiceCreateDate}',
        'email-templates.placeholders.invoice-create-date'
    ],
    [
        '${invoiceUrl}',
        'email-templates.placeholders.invoice-url'
    ],
    [
        '${shop.domain}',
        'email-templates.placeholders.shop-url'
    ],
    [
        '${shop.name}',
        'email-templates.placeholders.shop-name'
    ],
    [
        '${orderId}',
        'email-templates.placeholders.order-id'
    ],
    [
        '${orderDate}',
        'email-templates.placeholders.order-date'
    ],
    [
        '${orderShipment}',
        'email-templates.placeholders.order-shipment'
    ],
    [
        '${invoiceAddress}',
        'email-templates.placeholders.invoice-address'
    ],
    [
        '${deliveryAddress}',
        'email-templates.placeholders.delivery-address'
    ],
    [
        '${shippingCost}',
        'email-templates.placeholders.shipping-cost'
    ],
    [
        '${orderedProducts}',
        'email-templates.placeholders.ordered-products'
    ],
    [
        '${warehouseDeliveryTime}',
        'email-templates.placeholders.warehouse-delivery-time'
    ]
]

export default emailPlaceholders.map(([placeholder, i18nTranslation]) => ({
    placeholder,
    i18nTranslation
}));